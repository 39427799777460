import React, { useState } from 'react';
import { graphql } from 'gatsby';

import Layout from '~/components/Layout';
import { Link } from 'gatsby';
import SeoOverride from '../components/global/SeoOverride';

const Disclaimer = ({ data: { allWpLegalDisclaimer, wpLegalDisclaimer, wp } }) => {
  const pathname = typeof window !== 'undefined' && window.location.pathname;

  const allDocuments = allWpLegalDisclaimer.nodes;
  const currentDocument = wpLegalDisclaimer.landingDisclaimers.documents;
  const privateDoc = wpLegalDisclaimer.landingDisclaimers.private;

  return (
    <Layout wp={wp} darkHeader={true} altHeader={true}>
      <SeoOverride post={wpLegalDisclaimer} />
      <LegalNavigation documents={allDocuments} pathname={pathname} privateDoc={privateDoc} />
      <PdfViewer document={currentDocument} />
    </Layout>
  );
};

export default Disclaimer;

export const LegalNavigation = ({ documents, pathname, privateDoc }) => {
  if (privateDoc) {
    return (
      <section className="border-b bg-offWhite border-navy border-opacity-20 pt-[69px] lg:pt-[91px]">
        <div className="container flex overflow-x-scroll gap-x-4 sm:gap-x-8 md:gap-x-12 lg:justify-center px-6 py-4">
          {documents.map(function (document, id) {
            return (
              <Link
                to={document.uri}
                className={`font-bold tracking-[-0.01] font-heading leading-[1.1] text-[14px] md:text-base py-2 duration-300 hover:text-blue whitespace-nowrap ${
                  document.uri === pathname ? 'text-blue' : 'text-black'
                }`}
                key={`disclaimertab${id}`}
              >
                {document.title}
              </Link>
            );
          })}
        </div>
      </section>
    );
  } else {
    return (
      <section className="border-b bg-offWhite border-navy border-opacity-20 pt-[69px] lg:pt-[91px]">
        <div className="container flex overflow-x-scroll gap-x-4 sm:gap-x-8 md:gap-x-12 lg:justify-center px-6 py-4">
          {documents.map(function (document, id) {
            if (document?.landingDisclaimers?.private) {
            } else {
              return (
                <Link
                  to={document.uri}
                  className={`font-bold tracking-[-0.01] font-heading leading-[1.1] text-[14px] md:text-base py-2 duration-300 hover:text-blue whitespace-nowrap ${
                    document.uri === pathname ? 'text-blue' : 'text-black'
                  }`}
                  key={`disclaimertab${id}`}
                >
                  {document.title}
                </Link>
              );
            }
          })}
        </div>
      </section>
    );
  }
};

export const PdfViewer = ({ document }) => {
  return (
    <section>
      <div className="container bg-offWhite pt-12 pb-20 md:pt-28 md:pb-52">
        <div className="max-w-5xl mx-auto">
          {document.pdfUrl && (
            <div>
              <div className="pdf-iframe">
                <iframe src={`${document.pdfUrl}#view=FitH&toolbar=0`} width="100%" height="100%"></iframe>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export const pageQuery = graphql`
  query Disclaimer($id: String!) {
    wp {
      ...GeneratedWp
      header {
        globalUpdatedHeader {
          fieldGroupName
          headerUpdated {
            topLevelNavigation {
              link {
                title
                target
                url
              }
              addMenu
              subMenu {
                tabs {
                  heading
                  description
                  menuType
                  columnMenu {
                    columns {
                      heading
                      cards {
                        image {
                          ...Image
                        }
                        link {
                          title
                          url
                        }
                        description
                      }
                    }
                  }
                  rowMenu {
                    heading
                    cards {
                      image {
                        ...Image
                      }
                      link {
                        title
                        url
                      }
                      description
                    }
                  }
                  resourcesMenu {
                    listSubheading
                    listItems {
                      image {
                        ...Image
                      }
                      link {
                        title
                        url
                      }
                    }
                    cardsSubheading
                    cardItems {
                      thumbnail {
                        ...Image
                      }
                      link {
                        title
                        url
                      }
                      description
                    }
                  }
                }
              }
            }
            buttons {
              textLink {
                target
                title
                url
              }
              buttonLink {
                target
                title
                url
              }
            }
            socials {
              image {
                ...Image
              }
              link {
                target
                title
                url
              }
            }
          }
        }
      }
    }
    allWpLegalDisclaimer {
      nodes {
        id
        uri
        title
        landingDisclaimers {
          private
        }
      }
    }
    wpLegalDisclaimer(id: { eq: $id }) {
      title
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          srcSet
          sourceUrl
          localFile {
            publicURL
          }
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          srcSet
          localFile {
            publicURL
          }
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
      landingDisclaimers {
        private
        documents {
          name
          pdfUrl
        }
      }
    }
  }
`;
