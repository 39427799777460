import React from 'react';
import Seo from 'gatsby-plugin-wpgraphql-seo';
import { Helmet } from 'react-helmet';

const SeoOverride = ({ post }) => {
  return (
    <>
      <Seo {...post} title={post?.seo?.title} />
      <Helmet>
        <meta property="og:title" content={post?.seo?.title} />
        <meta property="twitter:title" content={post?.seo?.title} />
      </Helmet>
    </>
  );
};

export default SeoOverride;
